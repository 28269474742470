import React, {useState, useEffect, useContext} from "react";

import Select from 'react-select';
import get from 'lodash/get';
import './styles.scss';
import { toast } from 'react-toastify';
import { getRejectionReasonComment, getRejectionReasons } from "services/drivers";
import { LanguageContext } from "contexts/language";
import { useTspContext } from 'contexts/TspContextProvider/TspContextProvider';
import { useTranslation } from "react-i18next";


export const RejectionReasonDropDown = ({tsp_id='', entityType, selectedRejectionReason, setSelectedRejectionReason, setSelectedRejectionComment }) => {

    const [rejectionReasons, setRejectionReasons] = useState([]);
    
    const [language] = useContext(LanguageContext);
    const { t } = useTranslation();
    const { countryCode, fetchTspData } = useTspContext();

    useEffect(() => {
        const rejectionPayload = {
            entity_type: entityType, 
            locale : language,
            is_active: true,
            tsp_id
        }; 
        getRejectionReasonsList(rejectionPayload);
    },[entityType, language, tsp_id]);

    useEffect(()=> {
        if (!countryCode) {
            fetchTspData();
        }
    },[fetchTspData, countryCode]);

    useEffect(() => {
        if (selectedRejectionReason){
            const reqPayload = {
                entity_type: entityType, 
                reason: selectedRejectionReason,
                locale: countryCode,
                is_active: true,
                tsp_id
            };
            getSelectedRejectionComment(reqPayload);
        }
    },[selectedRejectionReason, countryCode, entityType, tsp_id]);


    async function getRejectionReasonsList(reqBody) {
        try {
            const response = await getRejectionReasons(reqBody);
            let rejectionReasonsList = get(response, 'data.reasons', []);
            rejectionReasonsList = rejectionReasonsList.map(({reason}) => ({
                value: reason,
                label: reason,
                customLabel: reason,
            }));
            setRejectionReasons(rejectionReasonsList);
        }
        catch(err){
            toast.error(err);
            setRejectionReasons([]);
        }
    }

    async function getSelectedRejectionComment(reqBody) {
        try {
            const response = await getRejectionReasonComment(reqBody);
            setSelectedRejectionComment(response.data.comment);
        }
        catch(err){
            toast.error(err);
        }
    }

    

    return (
        <div>
                <Select className="react-select-container" classNamePrefix="react-select" options={rejectionReasons} placeholder ={t("SELECT_REASON")}  onChange = {(selectedReason) => {
                    setSelectedRejectionReason(selectedReason.value);
                }}/>      
        </div>
    );
};
